<template>
  <div class="wrap">
   <Loading v-show="isLoading"/>
   <div v-show="!isLoading" class="wrap1">
    <van-row>
      <van-col span="22" offset="1" class="fieldTop">
        <div class="top-title" style="padding-left: 15px;margin-bottom:10px">推荐：输入正确网银流水号可以更快捷的推进放款流程</div>
          <van-field
            label-class="fieldLabel"
            v-model="netSerialNumber"
            input-align="right"
            maxlength="50"
            label="网银流水号"
            type="textarea"
            rows="1"
            autosize
            placeholder="请输入"
          />
          <van-field
            label-class="fieldLabel"
            v-model="netSerialName"
            input-align="right"
            maxlength="50"
            label="付款账户名称"
            type="textarea"
            rows="1"
            autosize
            placeholder="请输入"
          />
        <div class="top-red">以上字段必填（二选一）</div>
      </van-col>
      <van-col class="top-title">合同列表</van-col>
      <van-col class="contractCol" span="22" offset="1" v-for="(item,index) in list" :key="index" style="margin-top:10px;border-radius:5px;">
        <van-cell :center="true">
          <template #title></template>
          <template #label>
            <div class="cell-label">
              <span class="custom-label">合同编码：</span>
              <span class="custom-value">{{item.contractCode}}</span>
            </div>
            <div class="cell-label">
              <span class="custom-label">前置保证金：</span>
              <span class="custom-value">{{ item.totalRent }}元</span>
              <span style="float:right;font-size: 12px;color: #666;" @click="handdleShowPopup(index)">查看详情<van-icon name="arrow" style="font-size: 14px;position: relative;top: 3px;margin-left: 4px;" /></span>
            </div>
          </template>
        </van-cell>
      </van-col>
    </van-row>
    <van-popup
      v-model="showPopup"
      closeable
      close-icon-position="top-left"
      position="bottom"
      round
      :style="{ height: '70%' }"
    >
      <div class="tipTitile">合同信息</div>
      <van-cell-group class="popupCont">
        <van-cell title="客户姓名" :value="popupData.customerName || '--'" />
        <van-cell title="申请编号" :value="popupData.tradeCode || '--'" />
        <van-cell title="合同编号" :value="popupData.contractCode || '--'" />
        <van-cell title="产品名称" :value="popupData.productName || '--'" />
        <van-cell title="是否挂靠" :value="popupData.isAffiliate=='1' ? '是' :'否'" />
        <van-cell title="前置保证金" :value="popupData.totalRent || '--'" />
      </van-cell-group>
    </van-popup>
    <div class="btnBottom">
      <van-notice-bar color="#FF5100" background="#FDEEDF" left-icon="info-o">
        个人付款无法开具公司抬头的租息发票，请注意
      </van-notice-bar>
      <div class="textBootom">
        <span>合同数总计：{{contractSum}}</span>
        <span style="margin-left:20px;">前置保证金总计：<span style="color:#E50113;font-weight: bold;">{{amount}}元</span></span>
      </div>
      <van-col span="9" offset="1">
        <van-button round block type="default" @click="handdleGoback">
        重选付款合同
        </van-button>
      </van-col>
      <van-col span="11" offset="2">
        <van-button :loading="btnLoading" round block type="danger" @click="handdleSubmit">
        确认已付款
        </van-button>
      </van-col>
    </div>
    </div>
  </div>
    
</template>
<script>
  import {getOpenId,getConfirmCorpPayContractList,confirmPayInfo,resetContractInfo} from '@/server'
  import { Toast,Dialog } from 'vant';
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,domainUrl,hidewxOption} from '@/lib/utils';
  export default {
    name: 'publicPayment',
    components: {
      Loading
    },
  data() {
    return {
      cardId:this.$store.state.cardId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      accessToken:this.$store.state.accessToken,
      isLoading:true,
      netSerialNumber:'',
      netSerialName:'',
      showPopup:false,
      list:[],
      id:this.$route.query.id,
      amount:'',
      contractSum:'',
      popupData:{},
      btnLoading:false,
      flagSouce:this.$route.query.flagSouce
    };
  },
  mounted(){
    // let that = this;
    // sessionStorage.setItem('key', 'publicAdvanceDeposit2');
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    // 	if(sessionStorage.getItem('key') == 'contract2') {
    //     that.$router.push({ path: '/personalCenter' });
    //   }
    // }, false);
    if(this.flagSouce == "modify"){
      document.title = "修正付款信息"
    }else{
      document.title = "支付前置保证金-企业"
    }
  },
  created(){
    //隐藏分享
    hidewxOption()
    // Toast(sessionStorage.getItem("origin"))
    this.getDetail()
  },
  methods: {
    handdleShowPopup(index){
      this.popupData = this.list[index];
      this.showPopup = true;
    },
    handdleSubmit(){
      if(this.netSerialNumber=='' && this.netSerialName==''){
        Toast.fail('请输入网银流水号或付款账户名称')
      }else{
        this.btnLoading = true;
        confirmPayInfo({data:{
          id:this.id,
          origin:sessionStorage.getItem("origin"),//0 重选后添加数据 1原始数据
          netSerialNumber:this.netSerialNumber,
          netSerialName:this.netSerialName,
          certId:sessionStorage.getItem("loginCard")
        }}).then(res=>{
          if(res.status == '200'){
            this.btnLoading = false;
            this.$router.push({path: '/enterprise/enterprisePersonalCenter'});
            setTimeout(()=>{
              Toast.success({
                message: '确认付款成功',
                duration:'3000'
              });
            },1000)
          }else{
            this.btnLoading = false;
            Toast.fail({
              message: res.subMsg || res.msg,
              duration:'3000'
            });
          }
        })
      }
    },
    handdleGoback(){
      if(this.flagSouce == "modify"){
        sessionStorage.setItem("payInfoId",this.id);
        sessionStorage.setItem("source",'1');//0 正常 ，1 从修正付款信息-重签合同过来的
        this.$router.push({path: '/enterprise/enterpriseAdvanceDeposit'});
      }else{
        sessionStorage.setItem("source",'0');//0 正常 ，1 从修正付款信息-重签合同过来的
        this.$router.go(-1);
      }
      // const params = {
      //   id:this.id
      // }
      // resetContractInfo({data:params}).then(res=>{
      //   if(res.status == '200'){
      //     // this.$router.push({path: '/enterprise/enterpriseAdvanceDeposit'});
      //     this.$router.go(-1)
      //   }else{
      //     Toast.fail({
      //       message: res.subMsg || res.msg,
      //       duration:'3000'
      //     });
      //   }
      // })
    },
    getDetail(){
      const params = {
        id:this.id,
        origin: sessionStorage.getItem("origin") //判断从修改付款信息重签合同过来的//0 重选后添加数据 1原始数据
      }
      getConfirmCorpPayContractList({data:params}).then(res=>{
        const {data} = res;
        if(res.status == '200'){
          this.isLoading = false;
          this.list = res.data.contractList;
          this.amount = res.data.amount;
          this.contractSum = res.data.contractSum;
          this.netSerialNumber = data.payInfo.bankNo;
          this.netSerialName = data.payInfo.accountNo;
        }else{
          this.isLoading = false;
          Toast.fail({
            message: res.subMsg || res.msg,
            duration:'3000'
          });
        }
      })
    }
  },
};
</script>
<style>
  .popupCont .van-cell{
    font-size: 15px !important;
  }
  .popupCont .van-cell__value{
    flex: 2 !important;
  }
  .fieldLabel{
    color: #333;
  }
</style>>
<style scoped lang="less">
  .wrap{
    /* padding: 20px 0px;  */
    overflow: hidden;
    min-height: 100vh !important;
    background: #F8F8F8;
    .wrap1{
      min-height: 100vh;
      // padding-bottom: 140px;
      .contractCol:last-child{
        padding-bottom: 160px;
      }
    }
    .fieldTop{
      background-color: #fff;
      margin-top: 15px;
    }
    .top-title{
      color: #666;
      font-size: 12px;
      padding-left: 25px;
      padding-top: 12px;
    }
    .top-red{
      color: #E50113;
      font-size: 12px;
      padding:12px 0px 15px 15px;
    }
  }
  .btnBottom{
    position: fixed;
    width: 100%;
    background-color: #fff;
    bottom: 0px;
    padding-bottom: 15px;
    .textBootom{
      margin: 10px 0px 20px 20px;
      span{
        display: inline-block;
      }
    }
  }
  .tipTitile{
    color: #1A1A1A;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #eee;
    height: 60px;
    line-height: 60px;
  }
  .cell-label{
    margin: 10px 0px; 
  }
  .custom-label{
    color:#333333;
    font-size: 13px;
    font-weight: bold;
  }
  .custom-value{
    font-size: 13px;
    color:#333333;
  }
</style>